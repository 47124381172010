<template lang="html">
  <form class="form form-horizontal">
    <template v-if="iControls.length">
    <table class="table  table-bordered table-hover   table-responsive">
      <thead style="display:none;">
        <tr class="bg-primary">
          <th class="text-center">
            {{ $t("name") }}
          </th>
          <th class="text-center">
            {{ $t("value") }}
          </th>
        </tr>
      </thead>
      <tbody ref="dataEditor">
        <tr v-for="(item, index) in iControls" v-bind:key="index">
          <td class="text-right" style="" data-testid="name">
            <label class="text-danger" v-if="errors[index]">
              <i
                class="fa fa-exclamation-triangle"
                :title="errors[index]"
                data-testid="error"
              ></i>
              {{ item.name }}
            </label>
            <label class="" v-else>{{ item.name }}</label>
          </td>
          <td>
            <div style="min-width:250px;max-width:350px;">
              <DataValueInput
                v-bind:control="item"
                v-bind:entry="item.data"
                :enableTab="true"
                :enableSave="false"
                @input="item.value = $event"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    </template>
    <template v-else>
  <div class="alert bg-gray text-center">
    <h4>
      <i class="icon fa fa-info"></i>
      {{ $t("multiple_data_write_alert_title") }}
    </h4>
    <p>
      {{ $t("multiple_data_write_alert_description") }}
    </p>
  </div>
</template>
  </form>
</template>

<script>
import DataValueInput from "@/components/data-value-input.vue";
export default {
  name: "EquipmentDataEditorForm",
  components: {
    DataValueInput
  },
  data: function() {
    return {
      iControls: [], // use a local instance to avoid data refresh,
      errors: [],
      authentication: {
        execution: false,
        verification: false
      }
    };
  },
  props: {
    controls: {
      type: Array,
      required: false
    }
  },
  computed: {
    hasError: function() {
      return this.errors.filter((err) => err).length != 0;
    }
  },
  watch: {
    iControls: {
      deep: true,
      handler(nVal, oVal) {
        if (this.validate(nVal)) {
          this.$emit("input", nVal);
        } else {
          this.$emit("input", null);
        }
      }
    }
  },
  methods: {
    resetError: function() {
      this.errors = [];
    },
    hasChanged(ix) {
      const n = (this.iControls || [])[ix]
      const o = (this.controls || [])[ix]
      return (n && o && n && o.value != n.value);
    },
    validate: function(formData) {
      var self = this;
      self.resetError();
      let hasChange = false;
      for (var i in self.iControls) {
        var control = self.iControls[i];
        if (
          ("min" in control &&
            control.min != null &&
            control.value < control.min) ||
          ("max" in control &&
            control.max != null &&
            control.value > control.max) ||
          (!self.allowNegativeInput(control) && control.value < 0) ||
          (!self.allowDecimal(control) && control.value % 1 > 0)
        ) {
          self.errors[i] = self.$t("out_of_range");
        }
        if (
          "required" in control &&
          control.required != null &&
          control.required &&
          control.value == ""
        ) {
          self.errors[i] = self.$t("data_required");
        }
        if (self.hasChanged(i)) {
          hasChange = true;
        }
      }
      return hasChange && !self.errors.some((error) => error);
    },
    resetValue: function(item) {
      if ("default" in item) {
        item.value = item.default;
      } else if ("min" in item) {
        item.value = item.min;
      } else {
        item.value = 0;
      }
    },
    isToggle: function(item) {
      return item.dataType.match(/(bool|coil|input status)/gi) != null;
    },
    allowDecimal: function(item) {
      return !(
        item.dataType.match(/(bool|coil|integer|long|status)/gi) != null
      );
    },
    allowNegativeInput: function(item) {
      return !(
        item.dataType.match(/(unsigned|bool|coil|input status)/gi) != null
      );
    }
  },
  mounted: function() {
    var self = this;
    // freeze data values
    this.$set(
      this,
      "iControls",
      JSON.parse(JSON.stringify(this.controls || []))
        .sort((a, b) =>
          (a.local_storage_identity_number || a.data.memory_address) -
          (b.local_storage_identity_number || b.data.memory_address))
    );
    this.$nextTick(() => {
      $(self.$refs.dataEditor)
        .find("input:first")
        .focus();
    });
  }
};
</script>

<style lang="css" scoped>
.has-feedback .form-control {
  padding-right: 60px;
  background-color: transparent;
}

.feedback-text {
  right: 62px !important;
  font-size: 75%;
  text-align: left;
}

.errmsg {
  color: #b1b0b0;
}

.skin-dark .alert.bg-gray {
  color: var(--skin-dark-light) !important;
  background: var(--skin-dark-darker) !important;
}
</style>
